import arrowIcon from "assets/icons/arrow-icon.svg";
import logoutIcon from "assets/icons/logout-icon-white.svg";
import settingsIcon from "assets/icons/settings-icon.svg";
import userIcon from "assets/icons/user-icon.svg";
import { getPropertyNameByValue, isAdminUser } from "utils/helpers";
import { PROFESSIONS_LIST_MAP } from "utils/constants";
import avatarImg from "assets/icons/user-icon.svg";

export function MenuDropdown({
  showMenu,
  setShowMenu,
  loading,
  user,
  onClickProfile,
  onClickLogout,
  onClickAdmin,
  useLocation = () => {},
}) {
  const location = useLocation();

  const isAdmin = location?.pathname?.startsWith("/admin");

  return (
    <div
      className="flex relative gap-1 justify-center lg:justify-between items-center bg-black bg-opacity-40 px-3 py-1 rounded-2xl lg:w-56"
      onClick={() => setShowMenu((prev) => !prev)}
    >
      <div className="flex items-center whitespace-nowrap overflow-hidden text-ellipsis">
        {loading ? (
          <div className="skeleton w-10 h-10 rounded-full bg-slate-900"></div>
        ) : user?.profileImage ? (
          <img
            src={user?.profileImage}
            alt="avatar"
            className="w-10 h-10 rounded-full object-cover bg-purple"
          />
        ) : (
          <div className="w-10 h-10 rounded-full object-cover bg-purple items-center justify-center flex">
            <img src={avatarImg} alt="avatar" className="w-5 h-5" />
          </div>
        )}
        <div className="px-4 whitespace-nowrap overflow-hidden text-ellipsis">
          <p className=" text-white font-bold text-sm text-ellipsis whitespace-nowrap overflow-hidden">
            {user?.name || "-"}
          </p>
          {(user?.admin || isAdminUser()) && isAdmin ? (
            <p className="text-gray-300 text-xs w-full text-left capitalize">
              Admin
            </p>
          ) : (
            <p className="text-gray-300 text-xs w-full text-left capitalize">
              {getPropertyNameByValue(user?.profession, PROFESSIONS_LIST_MAP) ||
                "-"}{" "}
              {user?.profession === "screener" &&
                user?.screenerLevel === "level1" &&
                1}
              {user?.profession === "screener" &&
                user?.screenerLevel === "level2" &&
                2}
              {user?.profession === "screener" &&
                user?.screenerLevel === "level3" &&
                3}
            </p>
          )}
        </div>
      </div>
      <img
        src={arrowIcon}
        alt="arrow"
        className={`${showMenu ? "rotate-180" : ""}`}
      />
      {showMenu ? (
        <div className="bg-[#1A1B1E] absolute right-0 top-14 z-50 p-[8px] rounded-[12px] w-48 lg:w-56">
          <section>
            {/* <h4 className="text-white mb-4">Switch Profile</h4>
              <div className="flex flex-col gap-4">
                <ProfileItem
                  name={"Jim Helpert"}
                  title={"Producer"}
                  user={user}
                  active
                />
                <ProfileItem
                  name={"Dwight Shrute"}
                  title={"Songwriter"}
                  user={user}
                />
              </div> */}
            <button
              onClick={onClickProfile}
              className="flex items-center w-full text-center text-white p-[8px] text-sm hover:bg-[#23262F]"
            >
              <img src={settingsIcon} alt="profile" className="w-4 mr-3" />{" "}
              Profile
            </button>
            {user?.admin || isAdminUser() ? (
              <button
                onClick={onClickAdmin && onClickAdmin}
                className="flex items-center w-full text-center text-white p-[8px] text-sm hover:bg-[#23262F]"
              >
                <img src={userIcon} alt="admin" className="w-4 mr-3" /> Admin
              </button>
            ) : null}
            <button
              className="flex items-center w-full text-center mt-[8px] text-white p-[8px] text-sm hover:bg-[#23262F]"
              onClick={onClickLogout}
            >
              <img src={logoutIcon} alt="logout" className="w-4 mr-3" /> Logout
            </button>
          </section>
        </div>
      ) : null}
    </div>
  );
}
