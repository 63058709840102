import cutIcon from "assets/icons/trophy-icon.svg";
import nogoIcon from "assets/icons/nogo-icon.svg";
import screeningIcon from "assets/icons/screening-icon.svg";
import hourglassIcon from "assets/icons/hourglass-icon.svg";
import pitchedIcon from "assets/icons/accept-icon.svg";

export const SCREENER_LIST = [
  "Screener level 1",
  "Screener level 2",
  "Screener level 3",
];

export const SCREENER_LIST_MAP = {
  "Screener level 1": "level1",
  "Screener level 2": "level2",
  "Screener level 3": "level3",
};

export const GENRES_LIST = [
  "Afro-Pop",
  "Blues",
  "Children's",
  "Cinematic",
  "Classical",
  "Country",
  "Disco",
  "EDM",
  "Electronic",
  "Folk",
  "Funk",
  "Gospel/Religious",
  "Hiphop/Rap",
  "Indi-Pop",
  "Jazz",
  "C-Pop",
  "J-Pop",
  "K-Pop",
  "Latin",
  "Metal",
  "Pop",
  "Punk",
  "R&B",
  "Reggae/Caribbean",
  "Rock",
  "Singer/Songwriter",
  "Soul",
  "Vocal/Acapella",
  "World",
];

export const GENRES_LIST_CAPITAL = {
  Other: "other",
  "New Drops": "newdrops",
  AfroPop: "afropop",
  Blues: "blues",
  Childrens: "childrens",
  Cinematic: "cinematic",
  Classical: "classical",
  Country: "country",
  Disco: "disco",
  EDM: "edm",
  Electronic: "electronic",
  Folk: "folk",
  Funk: "funk",
  "Gospel/Religious": "gospelReligious",
  "Hip Hop/Rap": "hiphopRap",
  "Indi-Pop": "indipop",
  Jazz: "jazz",
  "C-Pop": "cpop",
  "J-Pop": "jPop",
  "K-Pop": "kPop",
  Latin: "latin",
  Metal: "metal",
  Pop: "pop",
  Punk: "punk",
  "R&B": "rnb",
  "Reggae/Caribbean": "reggaeCaribbean",
  Rock: "rock",
  "Singer/Songwriter": "singerSongwriter",
  Soul: "soul",
  "Vocal/Acapella": "vocalAcapella",
  World: "world",
  Expired: "expired",
};

export const genresEnum = {
  afropop: "afropop",
  blues: "blues",
  childrens: "childrens",
  cinematic: "cinematic",
  classical: "classical",
  country: "country",
  disco: "disco",
  edm: "edm",
  electronic: "electronic",
  folk: "folk",
  funk: "funk",
  gospelReligious: "gospelReligious",
  hiphopRap: "hiphopRap",
  indipop: "indipop",
  jazz: "jazz",
  cpop: "cpop",
  jPop: "jPop",
  kPop: "kPop",
  latin: "latin",
  metal: "metal",
  pop: "pop",
  punk: "punk",
  rnb: "rnb",
  reggaeCaribbean: "reggaeCaribbean",
  rock: "rock",
  singerSongwriter: "singerSongwriter",
  soul: "soul",
  vocalAcapella: "vocalAcapella",
  world: "world",
};

export const LANGUAGES_LIST = [
  "Non/Looking for instrumentals",
  "Other",
  "Arabic",
  "Danish",
  "Dutch",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hindi",
  "Italian",
  "Japanese",
  "Korean",
  "Mandarin",
  "Norwegian",
  "Persian",
  "Portuguese",
  "Russian",
  "Spanish",
  "Swedish",
  "Turkish",
];

export const LANGUAGES_LIST_OBJ = {
  none: "Non/Looking for instrumentals",
  other: "Other",
  arabic: "Arabic",
  danish: "Danish",
  dutch: "Dutch",
  english: "English",
  finnish: "Finnish",
  french: "French",
  german: "German",
  greek: "Greek",
  hindi: "Hindi",
  italian: "Italian",
  japanese: "Japanese",
  korean: "Korean",
  mandarin: "Mandarin",
  norwegian: "Norwegian",
  persian: "Persian",
  portuguese: "Portuguese",
  russian: "Russian",
  spanish: "Spanish",
  swedish: "Swedish",
  turkish: "Turkish",
};

export const ROLES_LIST = {
  SONG_WRITER: "songWriter",
  PRODUCER: "producer",
  SONG_WRITER_PRODUCER: "songWriterProducer",
  ANR: "anr",
  MANAGER: "manager",
  SUPERVISOR: "supervisor",
  CORPORATE: "corporate",
  SCREENER: "screener",
};

export const PROFESSIONS_LIST = [
  "Producer",
  "Songwriter",
  "Songwriter/Producer",
  "A&R",
  "Manager",
  "Music supervisor",
  "Corporate",
  "Screener",
];

export const PROFESSIONS_LIST_MAP = {
  Producer: "producer",
  Songwriter: "songWriter",
  "Songwriter/Producer": "songWriterProducer",
  "A&R": "anr",
  Manager: "manager",
  "Music supervisor": "supervisor",
  Corporate: "corporate",
  Screener: "screener",
};

export const STATUS_STATE = {
  hold: { name: "HOLD", icon: hourglassIcon },
  cut: { name: "CUT", icon: cutIcon },
  nogo: { name: "NO GO", icon: nogoIcon },
  screening: { name: "SCREENING", icon: screeningIcon },
  pitch: { name: "PITCHED", icon: pitchedIcon },
};

export const SCANDINAVIAN_LIST = {
  sweden: "Sweden",
  norway: "Norway",
  denmark: "Denmark",
  finland: "Finland",
};

export const EUROPEAN_LIST = {
  albania: "Albania",
  andorra: "Andorra",
  austria: "Austria",
  belarus: "Belarus",
  belgium: "Belgium",
  bosniaAndHerzegovina: "Bosnia and Herzegovina",
  bulgaria: "Bulgaria",
  croatia: "Croatia",
  czechia: "Czech Republic",
  cyprus: "Cyprus",
  estonia: "Estonia",
  france: "France",
  germany: "Germany",
  greece: "Greece",
  hungary: "Hungary",
  iceland: "Iceland",
  ireland: "Ireland",
  italy: "Italy",
  latvia: "Latvia",
  liechtenstein: "Liechtenstein",
  lithuania: "Lithuania",
  luxembourg: "Luxembourg",
  northMacedonia: "North Macedonia",
  malta: "Malta",
  moldova: "Moldova",
  monaco: "Monaco",
  montenegro: "Montenegro",
  netherlands: "Netherlands",
  poland: "Poland",
  portugal: "Portugal",
  romania: "Romania",
  russia: "Russia",
  sanMarino: "San Marino",
  serbia: "Serbia",
  slovakia: "Slovakia",
  slovenia: "Slovenia",
  spain: "Spain",
  switzerland: "Switzerland",
  ukraine: "Ukraine",
  unitedKingdom: "United Kingdom",
  vaticanCity: "Vatican City",
};

export const ASIAN_LIST = {
  afghanistan: "Afghanistan",
  armenia: "Armenia",
  azerbaijan: "Azerbaijan",
  bangladesh: "Bangladesh",
  bhutan: "Bhutan",
  brunei: "Brunei",
  cambodia: "Cambodia",
  china: "China",
  georgia: "Georgia",
  india: "India",
  indonesia: "Indonesia",
  japan: "Japan",
  kazakhstan: "Kazakhstan",
  northKorea: "North Korea",
  southKorea: "South Korea",
  kyrgyzstan: "Kyrgyzstan",
  laos: "Laos",
  malaysia: "Malaysia",
  maldives: "Maldives",
  mongolia: "Mongolia",
  nepal: "Nepal",
  pakistan: "Pakistan",
  philippines: "Philippines",
  singapore: "Singapore",
  sriLanka: "Sri Lanka",
  tajikistan: "Tajikistan",
  thailand: "Thailand",
  eastTimor: "East Timor",
  turkmenistan: "Turkmenistan",
  uzbekistan: "Uzbekistan",
  vietnam: "Vietnam",
};

export const MIDDLE_EASTER_LIST = {
  egypt: "Egypt",
  turkey: "Turkey",
  iran: "Iran",
  iraq: "Iraq",
  saudiArabia: "Saudi Arabia",
  yemen: "Yemen",
  syria: "Syria",
  jordan: "Jordan",
  israel: "Israel",
  lebanon: "Lebanon",
  palestine: "Palestine",
  oman: "Oman",
  kuwait: "Kuwait",
  qatar: "Qatar",
  bahrain: "Bahrain",
  unitedArabEmirates: "United Arab Emirates",
};

export const NORTH_AMERICA_LIST = {
  antiguaAndBarbuda: "Antigua and Barbuda",
  bahamas: "Bahamas",
  barbados: "Barbados",
  belize: "Belize",
  canada: "Canada",
  dominica: "Dominica",
  grenada: "Grenada",
  haiti: "Haiti",
  jamaica: "Jamaica",
  saintKittsAndNevis: "Saint Kitts and Nevis",
  saintLucia: "Saint Lucia",
  trinidadAndTobago: "Trinidad and Tobago",
  unitedStatesOfAmerica: "United States of America",
};

export const LATIN_LIST = {
  argentina: "Argentina",
  bolivia: "Bolivia",
  brazil: "Brazil",
  chile: "Chile",
  colombia: "Colombia",
  ecuador: "Ecuador",
  panama: "Panama",
  nicaragua: "Nicaragua",
  puertoRico: "Puerto Rico",
  mexico: "Mexico",
  guatemala: "Guatemala",
  dominicanRepublic: "Dominican Republic",
  elSalvador: "El Salvador",
  cuba: "Cuba",
  costaRica: "Costa Rica",
  honduras: "Honduras",
  guyana: "Guyana",
  paraguay: "Paraguay",
  peru: "Peru",
  suriname: "Suriname",
  uruguay: "Uruguay",
  venezuela: "Venezuela",
};

export const AFRICAN_LIST = {
  algeria: "Algeria",
  angola: "Angola",
  benin: "Benin",
  botswana: "Botswana",
  burkinaFaso: "Burkina Faso",
  burundi: "Burundi",
  cameroon: "Cameroon",
  caboVerde: "Cape Verde",
  centralAfricanRepublic: "Central African Republic",
  chad: "Chad",
  comoros: "Comoros",
  congoBrazzaville: "Congo-Brazzaville",
  congoKinshasa: "Congo-Kinshasa",
  democraticRepublicOfTheCongo: "Democratic Republic of the Congo",
  cotedDivoire: "Cote d'Ivoire",
  dijibouti: "Dijibouti",
  equatorialGuinea: "Equatorial Guinea",
  eritrea: "Eritrea",
  ethiopia: "Ethiopia",
  eswatini: "Eswatini",
  gabon: "Gabon",
  theGambia: "The Gambia",
  ghana: "Ghana",
  guinea: "Guinea",
  guineaBissau: "Guinea-Bissau",
  kenya: "Kenya",
  lesotho: "Lesotho",
  liberia: "Liberia",
  libya: "Libya",
  madagascar: "Madagascar",
  malawi: "Malawi",
  mali: "Mali",
  mauritania: "Mauritania",
  mauritius: "Mauritius",
  morocco: "Morocco",
  mozambique: "Mozambique",
  namibia: "Namibia",
  niger: "Niger",
  nigeria: "Nigeria",
  rwanda: "Rwanda",
  saoTomeAndPrincipe: "Sao Tome and Principe",
  senegal: "Senegal",
  seychelles: "Seychelles",
  sierraLeone: "Sierra Leone",
  somalia: "Somalia",
  southAfrica: "South Africa",
  southSudan: "Sudan",
  tanzania: "Tanzania",
  togo: "Togo",
  tunisia: "Tunisia",
  uganda: "Uganda",
  zambia: "Zambia",
  zimbabwe: "Zimbabwe",
};

export const OCEANIAN_LIST = {
  australia: "Australia",
  papuaNewGuinea: "Papua New Guinea",
  newZealand: "New Zealand",
  fiji: "Fiji",
  solomonIslands: "Solomon Islands",
  vanuatu: "Vanuatu",
  samoa: "Samoa",
  kiribati: "Kiribati",
  federatedStatesOfMicronesia: "Federated States of Micronesia",
  tonga: "Tonga",
  marshallIslands: "Marshall Islands",
  palau: "Palau",
  tuvalu: "Tuvalu",
  nauru: "Nauru",
};

export const COUNTRIES_LIST = {
  africa: AFRICAN_LIST,
  asia: ASIAN_LIST,
  europe: EUROPEAN_LIST,
  latinamerica: LATIN_LIST,
  middleeast: MIDDLE_EASTER_LIST,
  northamerica: NORTH_AMERICA_LIST,
  oceania: OCEANIAN_LIST,
  scandinavia: SCANDINAVIAN_LIST,
};

export const PRODUCER_TERMS_LIST = [
  {
    title: "Introduction",
    body: `By your registration or using this Music Platform you agree to the terms and conditions for use of the Music Platform and the services provided. If you do not agree to any of the provisions set out, you should not use this Music Platform`,
  },
  {
    title: "Description of the Music Platform",
    body: `The Music Platform VELV is a hosting service. Registered users of the Music Platform may submit, upload and post material such as audio, images, artwork and other material. You are solely liable for the material that you upload. You acknowledge and accept that VELV will submit and review the uploaded material for approval through screeners and that the material will only be distributed to third parties if it is in line with the musical brief and meets the quality approval of VELV.
  
    You may terminate your account at any time, provided that we are not obliged to return any material uploaded by you.`,
  },
  {
    title: "Ownership",
    body: `You agree and warrant that you have the responsibility for any and all audio recordings or other material that you upload on the Music Platform, and that you will indemnify VELV from any claims made from third parties regarding the use of such material including samples which have not been cleared or else infringe upon the right of any person or third party.
  
    You will continue to own your material and we will not claim any ownership rights. We are not responsible for any theft of the uploaded material by a third party and you are advised to have registered the copyrights in the underlying compositions with the collecting societies.`,
  },
  {
    title: "Rights Granted",
    body: `
    The rights granted to us by the upload include a worldwide, non-exclusive, royalty-free licence to use, distribute and perform that material you upload on the Music Platform for promoting and redistributing the material through to third parties.`,
  },
  {
    title: "Refund",
    body: `The audio files that pass the screening process are forwarded to the A&R or client, who then provides the final feedback status to the user through VELV's proprietary tracking system.
  
    Refunds are only issued if a music brief has been withdrawn by VELV, the client, or if VELV has failed to deliver according to the terms of this agreement."`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
    By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com .`,
  },
  {
    title: "Legal",
    body: `The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
  
    You will indemnify and keep us fully indemnified against losses and liabilities costs and expenses in respect of claims on the grounds that the material or any part thereof infringes the intellectual property rights of any third party.
    
    In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
    Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];

export const ANR_TERMS_LIST = [
  {
    title: "Introduction",
    body: `VELV grants you access and the approval required to be able to post music briefs on the VELV Music Platform. By your registration to the VELV account you accept the Terms and Conditions for the services hereunder. These Terms and Conditions create an Agreement between you and VELV.`,
  },
  {
    title: "Services",
    body: `You are required to listen to the submitted audio demos and evaluate them before the self-imposed deadline you set.`,
  },
  {
    title: "Rights Granted",
    body: `You warrant that you have the right to convey the briefs and upload material on behalf of the artist, record company, film company or production company.
  
        VELV takes no responsibility for disputes regarding uploaded material from your account such as images, logos, audio files, videos and text information.`,
  },
  {
    title: "Period",
    body: `The Term of this Agreement will commence upon signing and continue thereafter until the VELV account is terminated by either you or VELV.`,
  },
  {
    title: "Confidentiality",
    body: `The Submitted audio demos may not be resold or licensed to third parties without the authors' approval.
  
        You ensure that illegal copying and/or plagiarism of the Submitted audio demos shall not occur and that you will not infringe the copyright in the Submitted audio demos.`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
        By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com.`,
  },
  {
    title: "Legal",
    body: `
        The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
        
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
        Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];

export const SCREENER_TERMS_LIST = [
  {
    title: "Introduction",
    body: `You have been selected by VELV to become part of the screener team for VELV´s Music Platform. By your registration to the VELV account you accept the Terms and Conditions for the screening services provided by you hereunder. These Terms and Conditions create an Agreement between you and VELV.`,
  },
  {
    title: "Services",
    body: `You are expected as a screener to listen carefully to the material that has been submitted to you and comply with all reasonable instructions given by VELV in connection with the performance of the assignment.`,
  },
  {
    title: "Period",
    body: `The Term of this Agreement will commence upon signing and continue thereafter until the VELV account is terminated by either you or VELV.`,
  },
  {
    title: "Termination",
    body: `Both parties will have the ability to terminate the Agreement without notice by closing the account. In the event that VELV is required to close the account, any remaining funds will be transferred to the specified bank account.`,
  },
  {
    title: "Fees",
    body: `VELV will pay you an hourly fee for the time devoted to listening to material as part of the Services hereunder. By using the service, you agree to our hourly fee and the timer system displayed in the wallet view. If you do not agree to any of the provisions set out, you should not use this Music Platform.
  
      Your earnings accumulate, and the minimum amount for withdrawal is 50 USD, which cannot be paid out in parts. The withdrawal process takes approximately 1-5 business days, and we will do our best to comply with this timeline.`,
  },
  {
    title: "Tax",
    body: `You will be responsible for the payment of all taxes, social costs and other amounts due in your country of domicile in connection with any payment made under or in connection with the Services. For screeners that are Swedish VELV will have to collect your personal number in order to be able to report to the Swedish Tax Agency how much earnings you have received in each year (swe: kontrolluppgift).`,
  },
  {
    title: "Confidentiality",
    body: `You guarantee that you will not to a third party communicate any trade secrets relating to the business of VELV and that you will not to a third party outside the scope of the VELV Music Platform distribute data information, tracks of music, images or any other material that you have received as part of the Services. In the event that such information or material as aforementioned is used or distributed by you without VELV’s permission then your account will be terminated by VELV and may lead to legal action by VELV.`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
      By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com.`,
  },
  {
    title: "Legal",
    body: `The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
  
      In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
      Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];
