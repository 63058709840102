/* eslint-disable react-hooks/exhaustive-deps */
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, db, messaging } from "config/firebase-config";
import {
  ScrollToTop,
  deleteCookie,
  parseFirebaseErrors,
  calculateTimeDifference,
} from "utils/helpers";
import velvLogo from "assets/images/velv-logo.gif";
import dropIcon from "assets/icons/drop-icon.svg";
import starIcon from "assets/icons/star-icon.svg";
import pitchesIcon from "assets/icons/pitches-icon.svg";
import arrowIcon from "assets/icons/arrow-icon.svg";
import addIcon from "assets/icons/plus-add-icon.svg";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import homeIcon from "assets/icons/home-icon.svg";
import bellIcon from "assets/icons/bell-icon.svg";
import slidersIcon from "assets/icons/sliders-icon.svg";
import closeIcon from "assets/icons/close-icon.svg";
import hamburgerIcon from "assets/icons/hamburger-icon.svg";
import walletIcon from "assets/icons/wallet-icon.svg";
import betaIcon from "assets/images/beta.png";
import anrIcon from "assets/icons/user-song.svg";
import screenerIcon from "assets/icons/headphones-icon.svg";
import producerIcon from "assets/icons/notes-icon.svg";
import usersIcon from "assets/icons/users-icon.svg";
import reviewIcon from "assets/icons/review-icon.svg";
import { ROLES_LIST } from "utils/constants";
import { AuthContext } from "context/AuthContext";
import {
  MenuSectionFilters,
  MenuSectionNotifications,
} from "components/Sidebar/Sidebar";
import { onMessage } from "firebase/messaging";
import { MenuDropdown } from "./MenuDropdown";

export function Header({ updatedUserImage = 0, isAdmin = false }) {
  const { value } = useContext(AuthContext);
  const menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  onMessage(messaging, (payload) => {
    setNotifications((prev) => [...prev, payload?.notification]);
  });

  useLayoutEffect(() => {
    switch (location.pathname) {
      case "/":
      case "/genre/newdrops":
      case "/genre/hiphopRap":
      case "/genre/other":
      case "/admin":
      case "/admin/manager":
      case "/admin/supervisor":
      case "/admin/corporate":
        setTabActive(1);
        break;
      case "/favorites":
      case "/admin/screener":
      case "/drop/mydrops":
      case "/wallet":
        setTabActive(2);
        break;
      case "/my-pitches":
      case "/admin/producer":
      case "/admin/songWriter":
      case "/admin/songWriterProducer":
      case "/drop/myinbox":
        setTabActive(3);
        break;
      case "/drop/add":
      case "/admin/users":
        setTabActive(4);
        break;
      case "/admin/review":
        setTabActive(5);
        break;
      default:
        setTabActive(0);
        return;
    }
  }, [location]);

  const handleClick = (tab) => {
    setTabActive(tab);

    switch (tab) {
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/favorites");
        break;
      case 3:
        navigate("/my-pitches");
        break;
      case 4:
        navigate("/drop/add");
        break;
      default:
        return;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");
      deleteCookie("user");
    } catch (error) {
      console.error("Error: ", parseFirebaseErrors(error?.code));
    }
  };

  const getItem = async (id) => {
    const docRef = doc(db, "users", id);
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) setUser(user);
      else {
        const snapshot = await getDoc(docRef);
        setUser(snapshot.data());
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const fetchData = async () => {
    try {
      await getItem(auth?.currentUser?.uid);
    } catch (error) {
      console.error("Error getting item:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  useEffect(() => {
    if (updatedUserImage) {
      fetchData();
    }
  }, [updatedUserImage]);

  return (
    <>
      <div className="flex relative justify-between items-center px-0 lg:px-10 mt-6 lg:mt-14 h-[52px]">
        <img
          src={betaIcon}
          alt="beta"
          className="absolute -top-[2px] left-10 w-16 h-16 lg:-top-10 lg:left-0 lg:w-24 lg:h-24"
        />
        <ScrollToTop />
        <div className="hidden lg:flex xl:w-40"></div>
        <img
          src={hamburgerIcon}
          alt="hamburger"
          className="flex lg:hidden menu-icon active-icon"
          onClick={() => setShowSidebar(true)}
        />
        <MobileSideMenu
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          setShowNotifications={setShowNotifications}
          showNotifications={showNotifications}
          notifications={notifications}
        />
        <div className="hidden lg:flex bg-[#000000] justify-start items-center text-white rounded-2xl font-semibold bg-opacity-40 backdrop-blur-sm max-w-[718px] mx-auto w-fit relative z-20">
          {user?.profession === ROLES_LIST.PRODUCER ||
          user?.profession === ROLES_LIST.SONG_WRITER ||
          user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER ? (
            <ProducerHeader tabActive={tabActive} handleClick={handleClick} />
          ) : null}
          {user?.profession === ROLES_LIST.SCREENER ? (
            <ScreenerHeader
              navigate={navigate}
              tabActive={tabActive}
              handleClick={handleClick}
            />
          ) : null}
          {(user?.profession === ROLES_LIST.ANR ||
            user?.profession === ROLES_LIST.MANAGER ||
            user?.profession === ROLES_LIST.SUPERVISOR ||
            user?.profession === ROLES_LIST.CORPORATE) &&
          !isAdmin ? (
            <AnrHeader
              tabActive={tabActive}
              handleClick={handleClick}
              navigate={navigate}
            />
          ) : null}
          {isAdmin ? (
            <AdminHeader
              tabActive={tabActive}
              handleClick={handleClick}
              navigate={navigate}
            />
          ) : null}
        </div>
        <div ref={menuRef}>
          <MenuDropdown
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            loading={loading}
            user={user}
            onClickProfile={() => navigate("/account")}
            onClickLogout={handleLogout}
            onClickAdmin={() => navigate("/admin")}
            useLocation={useLocation}
          />
        </div>
      </div>
      <div className="flex lg:hidden bg-[#000000] justify-start items-center text-white rounded-2xl font-semibold bg-opacity-40 backdrop-blur-sm mx-auto w-full mt-6 lg:mt-0 relative z-40">
        {user?.profession === ROLES_LIST.PRODUCER ||
        user?.profession === ROLES_LIST.SONG_WRITER ||
        user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER ? (
          <ProducerHeader tabActive={tabActive} handleClick={handleClick} />
        ) : null}
        {user?.profession === ROLES_LIST.SCREENER ? (
          <ScreenerHeader
            navigate={navigate}
            tabActive={tabActive}
            handleClick={handleClick}
          />
        ) : null}
        {(user?.profession === ROLES_LIST.ANR ||
          user?.profession === ROLES_LIST.MANAGER ||
          user?.profession === ROLES_LIST.SUPERVISOR ||
          user?.profession === ROLES_LIST.CORPORATE) &&
        !isAdmin ? (
          <AnrHeader
            tabActive={tabActive}
            handleClick={handleClick}
            navigate={navigate}
          />
        ) : null}
        {isAdmin ? (
          <AdminHeader tabActive={tabActive} navigate={navigate} />
        ) : null}
      </div>
    </>
  );
}

function MobileSideMenu({
  showSidebar,
  setShowSidebar,
  setShowNotifications,
  showNotifications,
  notifications,
}) {
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  let countryFilters = JSON.parse(localStorage.getItem("filters")) || [];

  return (
    <div
      className={`flex flex-col w-full md:w-[375px] fixed px-8 h-screen top-0 bottom-0 pt-14 z-30 bg-[#141416] ease-in-out duration-300 cursor-pointer overflow-y-auto hide-scroll-bar ${
        showSidebar ? "left-0" : "-left-full"
      }`}
    >
      <section className="flex justify-between items-center mb-8">
        <img src={velvLogo} alt="velv" className="w-10" />
        <img
          src={closeIcon}
          alt="close"
          className="w-4 h-4 ml-auto"
          onClick={() => {
            if (showFilters) setShowFilters(false);
            if (showNotifications) setShowNotifications(false);
            setShowSidebar(false);
          }}
        />
      </section>

      {showNotifications && !showFilters && (
        <>
          <div
            className="flex gap-4 text-white items-center"
            onClick={() => setShowNotifications(false)}
          >
            <img
              src={arrowIcon}
              className="w-3.5 h-3.5 relative rotate-90"
              alt="right"
            />
            <p className="font-bold text-lg">Back</p>
          </div>
          <MenuSectionNotifications
            setSelectAll={setSelectAll}
            checkmarkIcon={checkmarkIcon}
            selectAll={selectAll}
            notifications={notifications}
          />
        </>
      )}

      {showFilters && !showNotifications && (
        <div>
          <div
            className="flex gap-4 text-white items-center"
            onClick={() => setShowFilters(false)}
          >
            <img
              src={arrowIcon}
              className="w-3.5 h-3.5 relative rotate-90"
              alt="right"
            />
            <p className="font-bold text-lg">Back</p>
          </div>
          <MenuSectionFilters
            setSelectAll={setSelectAll}
            checkmarkIcon={checkmarkIcon}
            selectAll={selectAll}
          />

          <button
            onClick={() => navigate(0)}
            className="text-center bg-purple w-full text-white rounded-full py-3 mt-8 font-bold text-sm mb-20"
          >
            Add filters
          </button>
        </div>
      )}
      {!showFilters && !showNotifications && (
        <section className="grid place-items-center mt-3 rounded-xl bg-[#23262F] border border-solid border-[#353945] shadow-lg">
          <label className="w-full">
            <input className="absolute scale-0" type="checkbox" />
            <div className="w-full block max-w-xs overflow-hidden pl-2 py-0 text-white rounded-b-none">
              <div
                onClick={() => {
                  setShowSidebar(false);
                  navigate("/");
                }}
                className="flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle my-2 px-1 rounded-xl ml-6"
              >
                <img src={homeIcon} alt="home" className="menu-icon" />
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Home
                </div>
              </div>
              <div className="w-full h-px relative bg-zinc-700" />
              <div
                onClick={() => {
                  setShowFilters(false);
                  setShowNotifications(true);
                }}
                className="relative flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle ml-6"
              >
                {notifications?.length ? (
                  <div className="bg-red-500 rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute top-2 left-3">
                    {notifications?.length}
                  </div>
                ) : null}
                <img src={bellIcon} alt="bell" className="menu-icon" />
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Notifications
                </div>
              </div>
              <div className="w-full h-px relative bg-zinc-700" />
              <div
                onClick={() => {
                  setShowNotifications(false);
                  setShowFilters(true);
                }}
                className="flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle ml-6"
              >
                <div className="relative">
                  <img
                    src={slidersIcon}
                    alt="sliders"
                    className="menu-icon"
                    onClick={() => setShowFilters(true)}
                  />
                  {countryFilters?.length ? (
                    <div className="bg-purple rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute -top-1 right-7 z-10 left-4">
                      {countryFilters?.length}
                    </div>
                  ) : null}
                </div>
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Filter
                </div>
              </div>
            </div>
          </label>
        </section>
      )}
    </div>
  );
}

function ProducerHeader({ tabActive, handleClick }) {
  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => handleClick(1)}
      >
        <img src={dropIcon} alt="drops" className="icon" />
        Drops
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab`}
        onClick={() => handleClick(2)}
      >
        <img src={starIcon} alt="favorites" className="icon" />
        Favorites
      </div>
      <div
        className={`${tabActive === 3 && "tab-active"} tab`}
        onClick={() => handleClick(3)}
      >
        <img src={pitchesIcon} alt="pitches" className="icon" />
        My Pitches
      </div>
    </>
  );
}

function ScreenerHeader({ navigate, tabActive }) {
  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => navigate("/")}
      >
        <img src={dropIcon} alt="drops" className="icon" />
        Drops
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab`}
        onClick={() => navigate("/wallet")}
      >
        <img src={walletIcon} alt="wallet" className="icon" />
        Wallet
      </div>
    </>
  );
}

const AnrHeader = memo(({ tabActive, navigate }) => {
  const [numMyDrops, setNumMyDrops] = useState(0);
  const [numMyInbox, setNumMyInbox] = useState(0);

  const fetchDrops = useCallback(async () => {
    try {
      const qDrops = query(
        collection(db, "drops"),
        where("userId", "==", auth?.currentUser?.uid)
      );
      const dropsSnapshot = await getDocs(qDrops);

      let myDropsCount = 0;
      let myInboxCount = 0;

      dropsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const today = Timestamp.fromDate(new Date());
        const timeDifference = calculateTimeDifference(today, data?.expireTime);

        if (
          Math.abs(timeDifference?.day) < 90 &&
          data?.status &&
          !data?.deliveryDate
        ) {
          myDropsCount++;
        }

        if (
          Math.abs(timeDifference?.day) < 90 &&
          data?.status &&
          data?.deliveryDate
        ) {

          myInboxCount++;
        }
      });

      setNumMyDrops(myDropsCount);
      setNumMyInbox(myInboxCount);
    } catch (error) {
      console.error("Error fetching list", error);
    }
  }, []);

  useEffect(() => {
    fetchDrops();
  }, []);

  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => navigate("/drop")}
      >
        <img src={dropIcon} alt="drops" className="icon" />
        Drops
      </div>
      <div
        className={`${tabActive === 4 && "tab-active"} tab`}
        onClick={() => navigate("/drop/add")}
      >
        <img src={addIcon} alt="pitches" className="icon" />
        Add drop
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab lg:w-fit relative`}
        onClick={() => navigate("/drop/mydrops")}
      >
        <img src={starIcon} alt="my-drops" className="icon" />
        My Drops
        {numMyDrops ? (
          <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold absolute top-[0] right-0 z-10">
            {numMyDrops}
          </div>
        ) : null}
      </div>
      <div
        className={`${tabActive === 3 && "tab-active"} tab lg:w-fit relative`}
        onClick={() => navigate("/drop/myinbox")}
      >
        <img src={pitchesIcon} alt="my-inbox" className="icon" />
        My Inbox
        {numMyInbox ? (
          <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold absolute top-[0] right-0 z-10">
            {numMyInbox}
          </div>
        ) : null}
      </div>
    </>
  );
});

function AdminHeader({ tabActive, navigate }) {
  const menuRefANR = useRef(null);
  const menuRefProd = useRef(null);
  const [showANRMenu, setShowANRMenu] = useState(false);
  const [showProdMenu, setShowProdMenu] = useState(false);
  const [newAR, setNewAR] = useState(0);
  const [newScreener, setScreener] = useState(0);
  const [newDrops, setNewDrops] = useState(0);
  const [anrCount, setANRCount] = useState({
    anr: 0,
    manager: 0,
    supervisor: 0,
    corporate: 0,
  });

  const fetchDrops = async () => {
    try {
      const dropsCollection = collection(db, "drops");
      const q = query(dropsCollection);
      const dropsSnapshot = await getDocs(q);
      const dropsList = [];
      dropsSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const timestampDate = data?.expireTime?.toDate();
        const now = new Date();

        if (!data.status && timestampDate > now) {
          dropsList.push({
            _id: doc.id,
            ...doc.data(),
          });
        }

        setNewDrops(dropsList?.length || 0);
      });
    } catch (error) {
      console.error("Error fetching list", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const anr = { anr: 0, manager: 0, supervisor: 0, corporate: 0 };
      const usersCollection = collection(db, "users");
      const q = query(usersCollection);
      const usersSnapshot = await getDocs(q);
      const anrList = [];
      const screenerList = [];
      usersSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (
          ["anr", "manager", "supervisor", "corporate"].includes(
            data?.profession
          ) &&
          !data?.verifiedByVelv
        ) {
          anr[data?.profession] += 1;
          anrList.push({
            _id: doc.id,
            ...doc.data(),
          });
        }
        if (data?.profession === "screener" && !data?.verifiedByVelv) {
          screenerList.push({
            _id: doc.id,
            ...doc.data(),
          });
        }
      });
      setANRCount(anr);
      setScreener(screenerList?.length || 0);
      setNewAR(anrList?.length || 0);
    } catch (error) {
      console.error("Error fetching list", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [tabActive]);

  useEffect(() => {
    fetchDrops();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRefANR.current && !menuRefANR.current.contains(event.target)) {
        setShowANRMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showANRMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRefProd.current && !menuRefProd.current.contains(event.target)) {
        setShowProdMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProdMenu]);

  return (
    <>
      <div className="overflow-x-scroll no-scrollbar flex relative w-full">
        <div
          ref={menuRefANR}
          className="flex gap-1 items-center relative w-[90px] lg:w-fit"
        >
          <div
            className={`${
              tabActive === 1 ? "tab-active" : ""
            } tab !px-4 relative`}
            onClick={(e) => {
              e.stopPropagation();
              setShowANRMenu((prev) => !prev);
            }}
          >
            <img src={anrIcon} alt="anr" className="icon" />
            A&R
            <button className="h-5 flex items-center filter-custom-color">
              <img
                src={arrowIcon}
                alt="arrow"
                className={`${showANRMenu ? "rotate-180" : ""} w-2 h-2`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowANRMenu((prev) => !prev);
                }}
              />
            </button>
          </div>
          {newAR ? (
            <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold absolute top-[0] right-0 z-10">
              {newAR}
            </div>
          ) : null}
        </div>
        <div
          className={`${
            tabActive === 2 && "tab-active"
          } tab !px-4 w-[90px] lg:w-fit relative`}
          onClick={() => navigate("/admin/screener")}
        >
          <img src={screenerIcon} alt="screener" className="icon" />
          Screener
          {newScreener ? (
            <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold absolute top-[0] right-0 z-10">
              {newScreener}
            </div>
          ) : null}
        </div>
        <div
          ref={menuRefProd}
          className="flex gap-1 items-center w-[90px] lg:w-fit relative"
          onClick={(e) => {
            e.stopPropagation();
            setShowProdMenu((prev) => !prev);
          }}
        >
          <div className={`${tabActive === 3 && "tab-active"} tab !px-4`}>
            <img src={producerIcon} alt="prod/song" className="icon" />
            Prod/Song
            <button className="h-5 flex items-center filter-custom-color">
              <img
                src={arrowIcon}
                alt="arrow"
                className={`${showProdMenu ? "rotate-180" : ""} w-2 h-2`}
              />
            </button>
          </div>
        </div>
        <div
          className={`${
            tabActive === 4 && "tab-active"
          } tab !px-4 w-[90px] lg:w-fit relative`}
          onClick={() => navigate("/admin/users")}
        >
          <img src={usersIcon} alt="users" className="icon" />
          Users
        </div>
        <div
          className={`${
            tabActive === 5 && "tab-active"
          } tab !px-4 text-center w-[90px] lg:w-fit`}
          onClick={() => navigate("/admin/review")}
        >
          <img src={reviewIcon} alt="review" className="icon" />
          Drops Review
          {newDrops ? (
            <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold absolute top-[0] right-0 z-10">
              {newDrops}
            </div>
          ) : null}
        </div>
      </div>
      {showANRMenu && (
        <div
          ref={menuRefANR}
          onClick={() => setShowANRMenu((prev) => !prev)}
          className="flex flex-col absolute mt-80 w-45 border-2 border-solid border-[#353945] bg-[#23262F] rounded-xl p-2 text-white z-[200] left-0"
        >
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer flex gap-4 items-center"
            onClick={() => {
              navigate("/admin");
            }}
          >
            A&R
            {anrCount?.anr ? (
              <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold">
                {anrCount?.anr}
              </div>
            ) : null}
          </div>
          <div className="h-[1px] bg-[#353945] my-3"></div>
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer flex gap-4 items-center"
            onClick={() => {
              navigate("/admin/manager");
            }}
          >
            Manager
            {anrCount?.manager ? (
              <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold">
                {anrCount?.manager}
              </div>
            ) : null}
          </div>
          <div className="h-[1px] bg-[#353945] my-3"></div>
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer flex gap-4 items-center"
            onClick={() => navigate("/admin/supervisor")}
          >
            Music supervisor
            {anrCount?.supervisor ? (
              <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold">
                {anrCount?.supervisor}
              </div>
            ) : null}
          </div>
          <div className="h-[1px] bg-[#353945] my-3"></div>
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer flex gap-4 items-center"
            onClick={() => navigate("/admin/corporate")}
          >
            Corporate
            {anrCount?.corporate ? (
              <div className="bg-red-500 rounded-full flex justify-center items-center w-[30px] h-[20px] text-sm text-white font-bold">
                {anrCount?.corporate}
              </div>
            ) : null}
          </div>
        </div>
      )}
      {showProdMenu && (
        <div
          ref={menuRefProd}
          onClick={() => setShowProdMenu((prev) => !prev)}
          className="flex flex-col absolute mt-64 w-48 border-2 border-solid border-[#353945] bg-[#23262F] rounded-xl p-2 text-white z-100 left-24 md:left-60"
        >
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer"
            onClick={() => navigate("/admin/producer")}
          >
            Producer
          </div>
          <div className="h-[1px] bg-[#353945] my-3"></div>
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer"
            onClick={() => navigate("/admin/songWriter")}
          >
            Songwriter
          </div>
          <div className="h-[1px] bg-[#353945] my-3"></div>
          <div
            className="p-2 rounded-xl hover:bg-[#14141680] cursor-pointer"
            onClick={() => navigate("/admin/songWriterProducer")}
          >
            Songwriter Producer
          </div>
        </div>
      )}
    </>
  );
}
