import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  AFRICAN_LIST,
  ASIAN_LIST,
  EUROPEAN_LIST,
  GENRES_LIST_CAPITAL,
  LATIN_LIST,
  MIDDLE_EASTER_LIST,
  NORTH_AMERICA_LIST,
  OCEANIAN_LIST,
  SCANDINAVIAN_LIST,
} from "./constants";

dayjs.extend(duration);

export const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export const parseFirebaseErrors = (text) => {
  switch (text) {
    case "auth/email-already-exists":
    case "auth/invalid-email":
    case "auth/invalid-password":
    case "auth/invalid-credential":
      return "Email or password not valid";
    case "auth/internal-error":
    case "auth/email-already-in-use":
      return "Email already in use";
    default:
      return "Something went wrong, please try again!";
  }
};

export const formatDate = (date, justDate = false, local = "en-us") => {
  const today = new Date(date?.toDate());
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // month is zero-based
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  // Use ISO 8601-compliant format: YYYY-MM-DD
  const formatted = `${yyyy}-${mm}-${dd}`;

  if (justDate) {
    return new Date(formatted).toLocaleDateString(local, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else
    return today.toLocaleDateString(local, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
};


export const isValidUrl = (url) => {
  const urlPattern = new RegExp(
    "^" +
      // Protocol (optional)
      "((http|https|ftp):\\/\\/)?\\s*" +
      // www. (optional)
      "(www\\.)?" +
      // Domain name with at least one dot and a valid TLD
      "(([a-zA-Z0-9-]+\\.)+)" +
      // Top-Level Domain (TLD) with at least two characters
      "([a-zA-Z]{2,6})" +
      // Port (optional)
      "(\\:\\d{1,5})?" +
      // Path (optional)
      "(\\/[-a-zA-Z0-9%_.~+]*)*" +
      // Query string (optional)
      "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" +
      // Fragment (optional)
      "(\\#[-a-zA-Z0-9_]*)?$",
    "i"
  );

  const strictPattern = new RegExp(
    "^" +
      // www. (required)
      "(www\\.)" +
      // Domain name with at least one dot and a TLD
      "([a-zA-Z0-9-]+\\.)" +
      // Top-Level Domain (TLD) with at least two characters
      "([a-zA-Z]{2,6})" +
      // Port (optional)
      "(\\:\\d{1,5})?" +
      // Path (optional)
      "(\\/[-a-zA-Z0-9%_.~+]*)*" +
      // Query string (optional)
      "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" +
      // Fragment (optional)
      "(\\#[-a-zA-Z0-9_]*)?$",
    "i"
  );

  if (url.slice(0, 4) === "www.") return strictPattern.test(url.trim());

  // Check if the URL matches the strict pattern for www. or the general pattern
  return urlPattern.test(url.trim());
};

export const isValidSocialMediaLink = (link) => {
  const instagramPattern = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/\w+/i;
  const twitterPattern = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/\w+/i;
  const facebookPattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:facebook\.com|fb\.me)\/(?:[^/]+\/?)?$/i;
  const linkedinPattern =
    /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:company\/[^/?]+|in\/[^/?]+)/i;
  const xPattern = /^(?:https?:\/\/)?(?:www\.)?x\.com\/\w+/i;

  if (instagramPattern.test(link)) {
    return "Instagram";
  } else if (twitterPattern.test(link)) {
    return "Twitter";
  } else if (facebookPattern.test(link)) {
    return "Facebook";
  } else if (linkedinPattern.test(link)) {
    return "Linkedin";
  } else if (xPattern.test(link)) {
    return "X";
  } else {
    return false;
  }
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16)?.toUpperCase();
  });
}

export function displayDate(number) {
  let num = Math.abs(number);
  if (num < 10) num = "0" + num;
  return num;
}

export function calculateTimeDifference(startDate, endDate, setTime) {
  // Parsing the dates using Day.js
  if (typeof startDate === "object") {
    startDate = new Date();
    endDate = new Date(endDate?.toDate());
  }
  const dateCreated = new Date(startDate);
  const dateExpire = endDate;
  const start = dayjs(dateCreated);
  const end = dayjs(dateExpire);
  // Calculating the duration between the two dates
  const duration = dayjs?.duration(end.diff(start));
  // Getting the duration in days, hours, minutes, and seconds
  const days = duration?.months()
    ? Math.round(duration?.asDays())
    : duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (setTime) setTime(seconds);
  // Formatting to ensure not to exceed 60 seconds, minutes, or hours
  const formattedDays = days;
  const formattedHours = hours % 24;
  const formattedMinutes = minutes % 60;
  const formattedSeconds = seconds % 60;

  return {
    day: formattedDays,
    hour: formattedHours,
    minute: formattedMinutes,
    second: formattedSeconds,
  };
}

export const getPropertyNameByValue = (value, list) => {
  for (const propName in list) {
    if (list[propName] === value) {
      return propName;
    }
  }
  return null; // Value not found in the object
};

export const handleFavorites = (drop) => {
  const favorites = localStorage.getItem("favorites");
  let list = {};
  if (favorites) {
    list = JSON.parse(favorites);
    if (list[drop?.dropId]) delete list[drop?.dropId];
    else list = { ...list, [drop?.dropId]: drop };
  } else list = { [drop?.dropId]: drop };
  localStorage.setItem("favorites", JSON.stringify(list));
};

export const getAllContinentsList = () => {
  const list = [
    "africa",
    "asia",
    "europe",
    "latinamerica",
    "middleeast",
    "northamerica",
    "oceania",
    "scandinavia",
  ];

  return list;
};

export const getAllCountriesList = () => {
  const list = [
    ...Object.values(AFRICAN_LIST),
    ...Object.values(ASIAN_LIST),
    ...Object.values(EUROPEAN_LIST),
    ...Object.values(LATIN_LIST),
    ...Object.values(MIDDLE_EASTER_LIST),
    ...Object.values(NORTH_AMERICA_LIST),
    ...Object.values(OCEANIAN_LIST),
    ...Object.values(SCANDINAVIAN_LIST),
  ];

  return list;
};

export const getAllCountriesObject = () => {
  const list = {
    ...AFRICAN_LIST,
    ...ASIAN_LIST,
    ...EUROPEAN_LIST,
    ...LATIN_LIST,
    ...MIDDLE_EASTER_LIST,
    ...NORTH_AMERICA_LIST,
    ...OCEANIAN_LIST,
    ...SCANDINAVIAN_LIST,
  };

  return list;
};

export const getCountryFullName = (regionalOffice) => {
  const allCountries = getAllCountriesObject();
  return allCountries?.[regionalOffice] || "-";
};

export const convertSecondsToHMS = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

export const calculateTotalAmount = (
  hours,
  minutes,
  seconds,
  hourlyRate,
  currency = "usd"
) => {
  // Convert time to decimal hours
  const decimalHours = hours + minutes / 60 + seconds / 3600;

  // Calculate total amount
  const totalAmount = decimalHours * hourlyRate;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(totalAmount || 0);
};

export const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const parseURLEncoding = (url) => {
  const originalUrl = url;

  const transformedUrl = originalUrl
    .replace(/%2F/g, "%252F")
    .replace(/:/g, "%3A")
    .replace(/\//g, "%2F")
    .replace(/\?/g, "%3F")
    .replace(/=/g, "%3D")
    .replace(/&/g, "%26");

  return transformedUrl;
};

export const parseRequestQuantityPitch = (value, size) => {
  const values = { "0-100": 100, "0-200": 200, All: size, Other: size };
  return values[value];
};

export const handleRedirectToApp = (path) => {
  window.location.href = process.env.REACT_APP_APPLICATION_BASE_URL + path;
};

export const generateCloudFunctionUrl = (id) => {
  let link = `https://${id}-${process.env.REACT_APP_CLOUD_FUNCTIONS_ID}`;
  return link;
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
};

export const fetchCountryFromIP = async (ip) => {
  if (!ip) {
    console.error("Could not fetch IP address");
    return "Unknown";
  }

  try {
    const response = await fetch(`https://api.country.is/${ip}`);
    const data = await response.json();
    const { country } = data;
    console.info("Country fetched from IP address: ", country);
    return country;
  } catch (error) {
    console.error("Error fetching country from IP address", error);
    return "Unknown";
  }
};

export const adaptGenres = (str) => {
  if (str === "Afro-Pop") {
    return "afropop";
  } else if (str === 'R&B') {
    return 'rnb'
  } else if (str === 'EDM') {
    return 'edm'
  } else if (str === 'Indi-Pop') {
    return 'indipop'
  } else if (str === 'C-Pop') {
    return 'cpop'
  } else {
    const word = str.replaceAll("'", "");
    const charactersToRemove = /[-()/']/g;
    const words = word
      .replace(charactersToRemove, " ")
      .replace("&", "n")
      .split(" ");

    for (let i = 1; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let wordFinal = words.join("");
    const firstChar = wordFinal.charAt(0).toLowerCase();
    const restOfString = wordFinal.slice(1);
    return firstChar + restOfString;
  }
};

export const getKeyByValue = (value) => {
  const data = Object.keys(GENRES_LIST_CAPITAL).find(
    (key) => GENRES_LIST_CAPITAL[key] === value
  );
  return data;
};

export const setCookie = (name, value) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2); // Set cookie to expire in 2 years
  document.cookie = `${name}=${value}; domain=.velvmusic.com; path=/; expires=${date.toUTCString()}`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; domain=.velvmusic.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const getGenreTitleByKey = (value) => {
  const data = Object.keys(GENRES_LIST_CAPITAL).find(
    (key) => GENRES_LIST_CAPITAL[key]?.toLowerCase() === value?.toLowerCase()
  );
  return data ?? "";
};

export const sendNoticeToCollab = async ({ fcmToken, title, body }) => {
  try {
    // THIS IS URL FOR PRODUCTION: https://sendnoticetofcmtoken-ri2nooqp6q-ew.a.run.app
    await fetch("https://sendnoticetofcmtoken-bld2aagi2a-ew.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fcmToken, title, body }),
    });
  } catch (error) {
    console.error("Error sending notification", error);
  }
};

export const doesCookieExist = (name) => {
  let list = {};
  const cookiesList = document.cookie?.replaceAll(" ", "")?.split(";");

  cookiesList.forEach((item) => {
    const data = item.split("=");
    list = { ...list, [data[0]]: data[1] };
  });

  return !!list[name];
};

export const roundNumberTwoDecimalPlaces = (num) => {
  return Math.floor(num * 100) / 100;
};

export const isWithinSixMonth = (creationDateVal) => {
  const creationDate = new Date(
    creationDateVal.seconds * 1000 + creationDateVal.nanoseconds / 1000000
  );
  const currentDate = new Date();

  // Calculate six months later from the creation date
  const sixMonthsLater = new Date(creationDate);
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

  // Compare current date with six months later date
  return currentDate < sixMonthsLater;
};

export const formatCamelCaseWords = (str) => {
  let result = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === char.toUpperCase() && i !== 0) {
      result += " ";
    }

    result += i === 0 ? char.toUpperCase() : char;
  }

  return result;
};

export const removeEmptyStrings = (obj) => {
  const keyOrder = [
    "artist",
    "label",
    "regionalOffice",
    "genre",
    "lyrics",
    "lookingFor",
    "importantNote",
    "referenceLinks",
  ];
  const cleanedObj = {};

  // Remove empty strings from arrays
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item) => item !== "");
    }
  });

  // Reorder the object based on the defined order
  keyOrder.forEach((key) => {
    if (obj[key] !== undefined) {
      cleanedObj[key] = obj[key];
    }
  });

  return cleanedObj;
};

export const isUserANR = (profession) => {
  const isANR = ["anr", "corporate", "manager", "supervisor"].includes(
    profession
  );

  return isANR;
};

export const isAdminUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.email === 'stress@velvmusic.com' && user?.velvId === '8DZQ') {
    return true
  } else if (user?.email === 'nico@lifestylerecords.com' && user?.velvId === 'UV3T') {
    return true
  } else {
    return false
  }
}
